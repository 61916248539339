import {computed, ref, watch} from "vue";
import {useDateFormat} from "@vueuse/core";
import {useRouter} from "vue-router";

export const baseUrl = 'https://nextgen.carads.io';

const cacheData = {};

export function setCache(key, value) {
    cacheData[key] = value;
}

export function getCache(key) {
    return cacheData[key] ?? null;
}

export function backToSearch($router, is_back_enabled = false) {
    const $route = $router.currentRoute.value;
    if ($router?.options?.history?.state?.back) {
        return is_back_enabled ? $router.push($router?.options?.history?.state?.back) : $router.go(-1);
    }

    if($route?.params?.base){
        return $router.push({name: 'search', params: {base: $route.params.base}});
    }

    window.location.href = '/';
}

const activeController = {
}

function fetchProductList({cid = null, fid = null, pid = null, page = 1, body = null, order = null, noCache = false}, key = null) {
    let url = null;
    let signal = null;
    let activeController = null;

    if (order === null) {
        order = 'price:asc';
    }

    if(key !== null){
        // detect if browser support AbortController
        if('AbortController' in window){
            activeController = new AbortController();
            signal = activeController.signal;
            key.value = activeController;
        }
    }


    let base = `${baseUrl}/feeds`;

    if (noCache) {
        base += '/nocache';
    }

    if (body === null) {
        const newSort = order.split(':');

        // check if newSort is valid
        if(['price', 'mileage', 'name', 'created'].indexOf(newSort[0]) === -1){
            newSort[0] = 'name';
        }

        if(['asc', 'desc'].indexOf(newSort[1]) === -1){
            newSort[1] = 'asc';
        }

        url = `${base}/${cid}/${fid}/${newSort[0]}/${newSort[1]}/${page}?time=${new Date().getTime()}`;
    } else {
        const newSort = order.split(':');

        if(['asc', 'desc'].indexOf(newSort[1]) === -1){
            newSort[1] = 'asc';
        }

        let newOrder = {};
        url = `${base}/${cid}/${fid}/${page}`;

        switch (newSort[0]) {
            case 'price':
                newOrder = {'pricing.cash.price': newSort[1]};
                break;
            case 'mileage':
                newOrder = {'info.mileage': newSort[1]};
                break;
            case 'name':
                newOrder = {
                    'details.brand': newSort[1],
                    'details.model': newSort[1],
                };
                break;
            case 'created':
                newOrder = {
                    'created': newSort[1]
                };
                break;
            default:
                newOrder = {
                    'details.brand': newSort[1],
                };
        }

        if (newOrder !== {}) {
            body = {...body, order: newOrder};
        }
    }

    const init = {
        method: body === null ? 'GET' : 'POST',
        body: body === null ? undefined : JSON.stringify(body)
    }

    if(signal !== null){
        init.signal = signal;
    }

    return fetch(url, init).then((res) => res.json()).finally(() => {
        if(key !== null){
            key.value = null;
        }
    });
}

function fetchProduct(cid, pid, type = 'car', noCache = false) {
    let base = `${baseUrl}/products`;

    if (noCache) {
        base = `${baseUrl}/product/nocache`;
    }

    let url = `${base}/${cid}/x${pid}`;

    if (type === 'car2') {
        url = `${base}/${cid}/${pid}`;
    }

    url += `?time=${new Date().getTime()}`;

    return fetch(url).then((res) => res.json());
}

export function getProduct(pid, type = 'car') {
    const product = ref({});
    const isLoading = ref(false);
    const isReady = ref(false);
    const feedSettings = {
        cid: null,
        noCache: false,
    }

    const images = computed(() => {
        return (product.value?.media?.images ?? [])
    });

    const image = computed(() => {
        // first image from media
        return images.value.length > 0 ? images.value[0] : {id: -1};
    });

    const video = computed(() => {
        return (product.value?.media?.videos?.source?.url ?? null)
    });

    function setup(cid = null, noCache = false) {
        feedSettings.cid = cid;
        feedSettings.noCache = noCache;
    }

    function exclude() {
        isLoading.value = true;
        return fetchProduct(feedSettings.cid, pid, type, feedSettings.noCache).then((res) => {
            isReady.value = true;
            isLoading.value = false;
            product.value = res ?? {};
            return res;
        }).catch(() => {
            isLoading.value = false;
            isReady.value = true;
        });
    }

    return {
        product,
        images,
        image,
        video,
        isLoading,
        isReady,
        setup,
        exclude,
    }
}

function createAggregates(aggregates = {}, fullAggregates = {}) {
    const tmp = {};
    let items = Object.keys(aggregates);

    if (items.length === 0) {
        items = Object.keys(fullAggregates);
    }

    items.forEach((key) => {
        if (searchType[key] === 'range') {
            if (fullAggregates[key]) {
                tmp[key] = {
                    min: fullAggregates[key].min,
                    max: fullAggregates[key].max,
                }
            } else {
                tmp[key] = {
                    min: aggregates[key].min,
                    max: aggregates[key].max,
                }
            }
        } else if (searchType[key] === 'list') {
            tmp[key] = aggregates[key];
        }
    });

    return tmp;
}

function filter2Object(filter, aggregates) {
    const filterObject = {};
    Object.entries(filter).forEach(([key, value]) => {
        if (value === null) {
            return;
        }

        if (searchType[key] === 'range') {
            // if both values is default then do not add to filter
            if (value.min === aggregates.value[key]?.min && value.max === aggregates.value[key]?.max)
                return;

            if (value?.max === undefined || value?.min === undefined)
                return;

            if (value?.min && !value?.max) {
                filterObject[key] = {">=": value.min}
            } else if (!value?.min && value?.max) {
                filterObject[key] = {"<=": value.max}
            } else if (value?.min && value?.max) {
                filterObject[key] = {">=": value.min, "<=": value.max}
            }
        } else if (searchType[key] === 'list' && typeof value === "object" && value.length > 0) {
            filterObject[key] = {"=": value};
        } else if (searchType[key] === 'list' && typeof value === "string" && value.length > 0) {
            filterObject[key] = {"=": [value]};
        }
    });

    return filterObject;
}

function inMemory(key, value) {
    if (!('__cacheInMemory' in window)) {
        window.__cacheInMemory = new Map();
    }

    if (window.__cacheInMemory.has(key)) {
        return window.__cacheInMemory.get(key);
    }

    let call = value();
    window.__cacheInMemory.set(key, call);
    return call;
}

export function getSlider() {
    const total = ref(0);
    const isLoading = ref(false);
    const isReady = ref(false);
    const items = ref([]);
    const feedSettings = {
        cid: null,
        fid: null,
        filter: {},
        noCache: false,
    };

    function setup({cid = null, fid = null, filter = {}, noCache = false, order = 'price:asc'}) {
        feedSettings.cid = cid;
        feedSettings.fid = fid;
        feedSettings.filter = filter;
        feedSettings.noCache = noCache;
        feedSettings.order = order;
    }

    function exclude() {
        isLoading.value = true;
        isReady.value = false;

        if (Object.keys(feedSettings.filter).length !== 0) {
            return fetchProductList({
                ...feedSettings,
                body: {filter: feedSettings.filter},
                page: 1,
            }).then((res) => {
                isReady.value = true;
                isLoading.value = false;
                items.value = (res.list ?? []).slice(0, 10);
                return res;
            });
        }

        return inMemory(
            'slider.' + JSON.stringify(feedSettings),
            () => fetchProductList({
                ...feedSettings,
                page: 1,
            })
        ).then((res) => {
            isReady.value = true;
            isLoading.value = false;
            items.value = (res.list ?? []).slice(0, 10);
            return res;
        });
    }


    return {
        total,
        isLoading,
        isReady,
        items,
        setup,
        exclude,
    }
}

export function qucikSearch() {
    const total = ref(0);
    const total_before_filter = ref('-');
    const aggregates = ref({});
    const filter = ref({
        'details.brand': null,
        'details.model': null,
    });
    const isLoading = ref(false);
    const isReady = ref(false);

    const feedSettings = {
        cid: null,
        fid: null,
        noCache: false,
    }

    function setup({cid = null, fid = null, noCache = false}) {
        feedSettings.cid = cid;
        feedSettings.fid = fid;
        feedSettings.noCache = noCache;
    }

    watch(filter, () => {
        exclude().then(() => {
        });
    }, {deep: true});

    function exclude() {
        isLoading.value = true;
        isReady.value = false;

        const body = {
            page: 1,
            filter: filter2Object(filter.value, aggregates),
        };

        function fn() {
            return (Object.keys(body.filter).length === 0 ? fetchProductList({
                ...feedSettings, page: 1
            }) : fetchProductList({
                ...feedSettings, page: 1, body
            }))
        }

        return inMemory(
            'quicksearch.' + JSON.stringify(feedSettings) + JSON.stringify(body),
            () => fn()
        ).then((res) => {
            isReady.value = true;
            isLoading.value = false;
            total.value = res.total;
            total_before_filter.value = (total_before_filter.value > 0 ? total_before_filter.value : res.total);
            aggregates.value = createAggregates(res.aggregates, res.full_aggregates);
            return res;
        });
    }

    return {
        total,
        total_before_filter,
        aggregates,
        filter,
        isLoading,
        isReady,
        setup,
        exclude,
    }
}

export function getFeed(pid) {

    const signal = ref(null);
    const size = ref(0);
    const list = ref([]);
    const page = ref(pid ?? 1);
    const pages = ref(1);
    const total = ref(0);
    const aggregates = ref({});
    const filter = ref({});
    const isLoading = ref(false);
    const isReady = ref(false);
    const callback = ref(null);
    const onlyFilter = ref({});
    const sortOptions = ref([
        {value: 'price:asc', label: 'Laveste pris'},
        {value: 'price:desc', label: 'Højeste pris'},
        {value: 'mileage:asc', label: 'Kilometer (lav til høj)'},
        {value: 'mileage:desc', label: 'Kilometer (høj til lav)'},
        {value: 'name:asc', label: 'Navn A-Å'},
        {value: 'name:desc', label: 'Navn Å-A'},
        {value: 'created:desc', label: 'Nyeste først'},
        {value: 'created:asc', label: 'Ældste først'},
    ]);
    const urlParams = ref({
        'details.brand': null,
        'details.model': null,
    });
    const router = useRouter();
    const sort = ref('created:desc');

    const filterAfter = ref({});
    /* @var {<string>} feedSettings */
    const feedSettings = {
        cid: null,
        fid: null,
        pageKey: 'page',
        noCache: false,
    }

    function setupHook(call) {
        callback.value = call;
    }

    function setup({cid = null, fid = null, pageKey = 'page', _filter = {}, _sort = 'created:desc', noCache = false}) {
        feedSettings.cid = cid;
        feedSettings.fid = fid;
        feedSettings.pageKey = pageKey;
        feedSettings.noCache = noCache;
        onlyFilter.value = _filter.reduce((acc, item) => {
            acc[item?.k] = item;
            return acc;
        }, {});
        if (_sort !== 'created:desc') {
            sort.value = _sort;
        }
    }

    function filter2url() {
        const form = {
            query: {},
        };

        /**
         * Not sure we need this but for be sure array is sorted by key i will use it
         * @param a
         * @param b
         * @returns {number}
         */
        function sorter([a], [b]) {
            return a.localeCompare(b);
        }

        Object.entries(filter.value).sort(sorter).forEach(([key, value]) => {
            if (searchType[key] === 'list') {
                // check if urlParams has this key
                if (urlParams.value[key] !== undefined && urlParams.value[key] !== null) {
                    // we need to value(list) if it includes value from urlParams
                    const masterValue = urlParams.value[key];
                    let masterValueFound = false;

                    if (value.includes(masterValue)) {
                        form.query[key] = value.filter((v) => v !== masterValue);

                        if (form.query[key].length === 0) {
                            delete form.query[key];
                        }

                        masterValueFound = true;
                    } else if (value.length > 0) {
                        form.query[key + '[]'] = value;
                    }

                    if (!masterValueFound) {
                        /**
                         * if audi/a3 or /audi is in urlParams we need to remove it from urlParams
                         */
                        if (key === 'details.brand') {
                            form.name = 'search';
                            urlParams.value['details.brand'] = null;

                            if (urlParams.value['details.model'] !== null && filter.value['details.model']?.length > 0) {
                                form.query['details.model[]'] = urlParams.value['details.model'];
                                urlParams.value['details.model'] = null;
                            }
                        } else if (key === 'details.model' && urlParams.value['details.brand'] !== null) {
                            form.name = 'search-brand';
                            form.params = {
                                brand: urlParams.value['details.brand'].toString().toLowerCase(),
                            }
                        }
                    }
                } else if (value.length > 0) {
                    form.query[key + '[]'] = value;
                }
            } else if (searchType[key] === 'range') {

                if (!(value.min === aggregates.value[key]?.min && value.max === aggregates.value[key]?.max)) {
                    form.query[key + '[min]'] = value.min;
                    form.query[key + '[max]'] = value.max;
                }
            }
        });

        if (sort.value !== 'price:asc') {
            form.query['sort'] = sort.value;
        }

        form.query[feedSettings.pageKey] = page.value;

        return form;
    }

    function query2filter(query = {}, params = {}) {
        let security = false;
        if (params !== undefined && params !== null) {
            // can we detect brand and model from params
            if (params.brand !== undefined && params.brand !== null) {
                urlParams.value['details.brand'] = params.brand;
                filter.value['details.brand'] = [params.brand];
            }

            if (params.model !== undefined && params.model !== null) {
                filter.value['details.model'] = [params.model];
                urlParams.value['details.model'] = params.model;
                security = true;
            }
        }
        if (query !== undefined && query !== null) {
            // first detect if we have page in query
            if (feedSettings.pageKey in query && (query[feedSettings.pageKey] !== undefined && query[feedSettings.pageKey] !== null)) {
                page.value = parseInt(query[feedSettings.pageKey]) || 1;
            }

            if (query.sort !== undefined && query.sort !== null) {
                sort.value = query.sort;
            }

            Object.entries(query).forEach(([key, value]) => {
                if (key === 'page' || key === 'sort') {
                    return;
                }

                // regex for new key ([a-z\.]{1,})\[(min|max)?\]
                // i need min/max in cases of range
                const newKey = key.replace(/\[(min|max)?\]/g, '');
                const minMax = key.match(/\[(min|max)?\]/g);

                if (searchType[newKey] === 'list' && (!security || (security === true && newKey !== 'details.brand'))) {
                    if (typeof value === 'string') {
                        value = [value];
                    }

                    if (filter.value[newKey] !== undefined && filter.value[newKey] !== null) {
                        filter.value[newKey] = filter.value[newKey].concat(value);
                    } else {
                        filter.value[newKey] = value;
                    }
                } else if (searchType[newKey] === 'range') {
                    if (!minMax || minMax.length === 0) {
                        return;
                    }

                    if (minMax[0] === '[min]') {
                        if (!filter.value[newKey]) {
                            filter.value[newKey] = {};
                        }
                        filter.value[newKey].min = value;
                    } else if (minMax[0] === '[max]') {
                        if (!filter.value[newKey]) {
                            filter.value[newKey] = {};
                        }
                        filter.value[newKey].max = value;
                    }
                }
            });
        }

    }

    function onUpdate(pageNumber = 1) {
        page.value = pageNumber;
        const formData = filter2url();

        router.push(formData).then(r => {
            exclude().then(() => {
            });
        });
    }

    function excludeOnLoad(query, params) {
        if ((query !== undefined && query !== null) || (params !== undefined && params !== null)) {
            query2filter(query, params);
        }

        return exclude().then(() => {
            // check if range has both min and max values - if not, set missing value
            Object.entries(filter.value).forEach(([key, value]) => {
                if (searchType[key] === 'range') {
                    if(onlyFilter.value?.[key] === undefined){
                        console.log('not found, skip', key);
                        return;
                    }

                    if (!value.min) {
                        filter.value[key].min = aggregates.value[key].min;
                    }
                    if (!value.max) {
                        filter.value[key].max = aggregates.value[key].max;
                    }
                }
            });

            if (params !== undefined && params !== null) {
                // we have no slug, so but search work fine with lower case and upper case
                // so for fix it in filter, we need find brand and model in aggregates and set it to filter value if exist
                for (const x of ['brand', 'model']) {
                    if (params[x] !== undefined && params[x] !== null) {
                        if (aggregates.value?.['details.' + x]) {
                            Object.entries(aggregates.value['details.' + x]).forEach(([key, value]) => {
                                if (key.toString().toLowerCase() === params?.[x].toString().toLowerCase()) {
                                    const c = filter.value['details.' + x].findIndex((v) => v.toString().toLowerCase() === key.toString().toLowerCase());
                                    if (c !== -1) {
                                        urlParams.value['details.' + x] = key;
                                        filter.value['details.' + x].splice(c, 1);
                                        filter.value['details.' + x].push(key);
                                    }
                                }
                            });
                        }
                    }
                }

            }
        });
    }

    watch(signal, (newValue, oldValue) => {
        console.log('signal', newValue, oldValue);
    }, {immediate: true});

    function exclude() {
        const body = {
            page: page.value,
            sort: sort.value,
            filter: filter2Object(filter.value, aggregates)
        };

        isLoading.value = true;
        filterAfter.value = body;

        const settings = {
            ...feedSettings,
            order: sort.value,
            page: page.value
        }

        if (Object.keys(body.filter).length !== 0) {
            settings.body = body;
        }

        if(signal.value !== null){
            signal.value?.abort();
            console.log('abort....');
            signal.value = null;
        }



        return fetchProductList(settings, signal).then((res) => {

            console.log('res', res);
            isReady.value = true;
            setTimeout(() => {
                isLoading.value = false;
            }, 10);
            list.value = (res.list ?? []).map((item) => {
                return {
                    type: 'car',
                    ...item,
                }
            });
            size.value = res.size ?? 0;
            // page.value = res.page ?? page.value; bug for now @TODO thomas fix that in api
            pages.value = res.pages ?? 1;
            total.value = res.total ?? 0;
            aggregates.value = createAggregates(res?.aggregates ?? {}, res?.fullaggregates ?? {});

            Object.entries(aggregates.value).forEach(([key, value]) => {
                if(onlyFilter.value?.[key] === undefined){
                    console.log('not found, skip', key);
                    return;
                }

                if (searchType[key] === 'range' && filter.value[key] === undefined) {
                    filter.value[key] = {
                        min: value.min,
                        max: value.max,
                    }
                } else if (searchType[key] === 'list' && filter.value[key] === undefined) {
                    filter.value[key] = [];
                }
            });

            return res;
        }).then((res) => {
            callback.value?.(res);
            return res;
        }).catch((e) => {
            console.log('error', e);

            return e;
        });
    }

    return {
        size,
        list,
        page,
        pages,
        total,
        isReady,
        aggregates,
        filter,
        isLoading,
        sortOptions,
        sort,
        setup,
        exclude,
        setupHook,
        excludeOnLoad,
        urlParams,
        filterAfter,
        onUpdate,
    }
}

// https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1#file-slugify-js
export function slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

export function numberOfDays(date) {
    if (date === null) return null;
    const a = new Date().getTime();
    const b = new Date(date.toString().replace(/-/g, "/")).getTime();

    return parseInt(((a - b) / (1000 * 3600 * 24)).toString());
}

const functionCache = {};

export function convertAndExecute(input, product, f, settings = {}) {
    // Check if the function has already been cached for this input

    if (f === undefined) {
        f = {};
    }

    if (f?.numberOfDays === undefined) {
        f.numberOfDays = numberOfDays;
    }

    if (f?.currentPrice === undefined) {
        f.currentPrice = function(){
            return useCurrentPrice(
                product?.url ?? [],
                settings?.extra_price ?? {},
                settings.price_priorities ?? {}
            );
        }
    }

    if (functionCache[input]) {
        // If so, use the cached function
        return executeFunction(functionCache[input], product, f);
    } else {
        // If not, generate the function and cache it
        const output = generateFunctionOutput(input);
        const testFunction = new Function('product, f', output);
        functionCache[input] = testFunction;
        return executeFunction(testFunction, product, f);
    }
}

function generateFunctionOutput(input) {
    // find all $\(\) and replace with product.$1
    const placeholderRegex = /\$\(([^$)]{1,})\)/g;
    let output = input;

    for (let e of input.matchAll(placeholderRegex)) {
        const c = '(' + e[1].split('.').reduce((acc, cur) => {
            return acc + `?.${cur}`;
        }, 'product') + ' ?? null)';

        output = output.replace(e[0], c + "\n");
    }

    // Wrap the resulting expression in an "if" statement
    output = `if (${output}) { return true; } else { return false; }`;

    return output;
}

function executeFunction(testFunction, product, f) {
    // Call the function with the product object and handle any errors that may occur
    try {
        return testFunction(product, f);
    } catch (error) {
        // Maybe some problem with the function (wordpress bug as i can see for now).
        console.warn(error);
        return false;
    }
}


/**
 * Work when testing in browser with var isted of const and let's.
 * @param input
 * @param product
 * @returns {*|boolean}
 */
export function convertAndExecute_first_version(input, product) {
    // Define a regular expression to match placeholders of the form "%variableName%"
    const placeholderRegex = /%(\w+)%/g;

    // Replace each placeholder with its corresponding property check
    let output = input.replace(placeholderRegex, "('$1' in product && product['$1'])");

    // Wrap the resulting expression in an "if" statement
    output = "if (" + output + ") { return true; } else { return false; }";

    // Create a function from the output string using new Function
    const testFunction = new Function('product', output);

    // Call the function with the product object and return the result
    try {
        return testFunction(product);
    } catch (e) {
        // Maybe some problem with the function (wordpress bug as i can see for now).
        console.error(e);
        return false;
    }
}

export function getValueByPath(object, path) {
    if (typeof path !== 'string') {
        return undefined;
    }

    const paths = path.split('.');

    let value = object;
    for (let i = 0; i < paths.length; i++) {
        if (value === undefined) {
            return undefined;
        }

        value = value[paths[i]];
    }

    return value;
}

const getValueCustomFunctionCache = {};

export function getValueByPathWithFilter(object, path, filter, args, custom = false) {
    const value = getValueByPath(object, path);

    if (filter) {
        try {
            if (custom) {
                if (!getValueCustomFunctionCache[filter]) {
                    getValueCustomFunctionCache[filter] = new Function('value', 'object', filter);
                }

                return getValueCustomFunctionCache[filter](value, object);
            } else {
                switch (filter) {
                    case 'currency':
                        return (args.length ? Currency(value, ...args) : Currency(value)) ?? null;
                    case 'int':
                        return parseInt(value);
                    case 'numberFormat':
                        return args.length > 0 ? NumberFormat(value, ...args) : NumberFormat(value, 2, ',', '.');
                    case 'date':
                        return (args.length > 0 ? useDateFormat(value, ...args) : useDateFormat(value, 'DD-MM-YYYY'))?.value ?? null;
                    case 'replace':
                        return value.replace(...args) ?? null;
                    case 'substr':
                        return value.substr(...args) ?? null;
                    default:
                        return value;
                }
            }
        } catch (e) {
            console.log(e);
            return value;
        }
    }

    return value;
}

export function simpleRules(settings, product, rule, f = {}) {
    if (!(settings?.r ?? false)) {
        return true;
    }

    return convertAndExecute(settings.r, product, f, {});
}

export const searchType = {
    "details.brand": "list",
    "details.model": "list",
    "details.variant": "list",
    "details.range.km": "list",
    "details.series": "list",
    "details.year": "range",
    "capacity.payload": "range",
    "capacity.trailerweight": "range",
    "capacity.totalweight": "range",
    "chassis.type": "list",
    "chassis.weight": "range",
    "chassis.length": "range",
    "chassis.width": "range",
    "chassis.height": "range",
    "info.doors": "range",
    "info.servicebook": "range",
    "info.gears": "range",
    "info.gear": "list",
    "info.type": "range",
    "info.color": "list",
    "info.mileage": "range",
    "info.registration": "range",
    "info.vin": "range",
    "engine.kmprliter": "range",
    "engine.propellant": "list",
    "pricing.cash.price": "range",
    "dealer.city": "list",
    "dealer.full": "list",
}

export function NumberFormat(number, decimals, decPoint, thousandsSep) {
    decimals = decimals || 0;
    number = decimals === 0 ? parseInt(number) : parseFloat(number);

    if (!decPoint || !thousandsSep) {
        decPoint = '.';
        thousandsSep = ',';
    }

    let roundedNumber = Math.round(Math.abs(number) * ('1e' + decimals)) + '';
    let numbersString = decimals ? roundedNumber.slice(0, decimals * -1) : roundedNumber;
    let decimalsString = decimals ? roundedNumber.slice(decimals * -1) : '';
    let formattedNumber = "";

    while (numbersString.length > 3) {
        formattedNumber += thousandsSep + numbersString.slice(-3);
        numbersString = numbersString.slice(0, -3);
    }

    return (number < 0 ? '-' : '') + numbersString + formattedNumber + (decimalsString ? (decPoint + decimalsString) : '');
}

export function Currency(number, before = false, {
    decimals = 2,
    useGrouping = true,
    currencyDisplay = 'code',
    currency = 'DKK',
    output = 'da'
}) {
    const c = new Intl.NumberFormat(
        output,
        {
            style: 'currency',
            currency: currency,
            useGrouping: useGrouping,
            currencyDisplay: currencyDisplay,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        }
    ).formatToParts(number);

    // find the currency symbol and move it to the front
    if (before) {
        let currencySymbol = c.find(e => e.type === 'currency');
        let currencyIndex = c.indexOf(currencySymbol);
        c.splice(currencyIndex, 1);
        c.unshift(currencySymbol);
    }

    return {
        price: c,
        toString: function () {
            return this.price.map(e => {
                return (e.type === 'currency' ? (e.value + ' ') : e.value)
            }).join('').trim();
        },
        toHtml: function () {
            return this.price.map(e => {
                return (e.type === 'currency' ? ('<span class="currency">' + e.value + '</span> ') : e.value)
            }).join('').trim();
        }
    };
}

export function loadPage() {
    const page = ref({});
    const isLoading = ref(false);
    const isReady = ref(false);

    function exclude({cid = null, pageInfo = false}) {
        console.log('exclude', cid, pageInfo);
        if (pageInfo === false) {
            isLoading.value = false;
            isReady.value = true;
            return Promise.resolve();
        }
        // first check session storage for page content
        if (sessionStorage.getItem('page.' + cid) !== null) {
            console.log('sessionStorage', cid);
            page.value = JSON.parse(sessionStorage.getItem('page.' + cid));
            isLoading.value = false;
            isReady.value = true;

            return Promise.resolve();
        }

        isLoading.value = true;
        isReady.value = false;
        return fetch(baseUrl + '/pages/' + cid + '/product?_time=' + Date.now()).then((res) => {
            return res.json();
        }).then((res) => {
            console.log('fetch', cid);
            sessionStorage.setItem('page.' + cid, JSON.stringify(res));
            page.value = res;
            isLoading.value = false;
            isReady.value = true;

            return res;
        });
    }

    return {
        page,
        isLoading,
        isReady,
        exclude
    }
}

/**
 * @param route
 * @returns {string} as unique key for path
 */
export function pathKey(route) {
    const name = route?.matched?.[0]?.name;

    if (typeof name === 'string' && name.startsWith('search')) {
        // remove last / from path (if exists)
        // - fix bug when search close select tag so user need to open it again first time when enter search page
        return route.path.endsWith('/') ? route.path.slice(0, -1) : route.path;
    }

    return route.fullPath;
}

export function eventList(event) {
    return ('__carads_hooks' in window) ?
        window.__carads_hooks?.filter((e) => e.hook === event) ?? []
        : []
}

export function triggerEvent(event, data = null) {
    return eventList(event).map((e) => {
        return e.callback(data);
    });
}

export function tailwindGridSpan(size = 1) {
    switch (size) {
        case 1:
            return '!ca-col-span-1';
        case 2:
            return '!ca-col-span-2';
        case 3:
            return '!ca-col-span-3';
        case 4:
            return '!ca-col-span-4';
        case 5:
            return '!ca-col-span-5';
        case 6:
        default:
            return '!ca-col-span-6';
    }

    return '!ca-col-span-6';
}

export function getValueByPathWithPages($page, $product, path) {
    if (path.startsWith('$page')) {
        return getValueByPath($page, path.replace('$page.', ''));
    }

    return getValueByPath($product, path);
}

export function useCurrentPrice(url, extra, priorities) {
    // find price with highest priority from priorities
    let correctIndex = 0;

    for (let i = 0; i < priorities.length; i++) {
        const index = url.findIndex((e) => e.id === priorities[i]);

        if (index !== -1) {
            correctIndex = index;
            break;
        }
    }

    const price = url?.[correctIndex]?.prices?.[0] ?? {
        name: 'unknown',
        price: 'Ukendt pris',
    };

    price.feed_name = url?.[correctIndex]?.name ?? null;

    if (extra && Object.keys(extra).length > 0) {
        // extra is object with keys and values
        price.total = Object.entries(extra).reduce((acc, [key, value]) => {
            if (value === null || value === undefined) {
                return acc;
            }

            return acc + (value?.price ?? 0);
        }, price?.price ?? 0);
    }

    return {
        ...price,
        extra
    }
}

export function doAll(e, call, className){
    console.log(document.querySelectorAll(e));
    document.querySelectorAll(e).forEach((el) => {
        el.classList[call](className);

        if(call === 'add'){
            // remove data-nosnippet from element
            el.setAttribute('data-nosnippet', '');
        }
        else
        {
            el.removeAttribute('data-nosnippet');
        }
    });
}