import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import {doAll} from "../global/global";

const routes = [
    {
        path: '/:base-biler/:brand/:model/:slug-:id(\\d+)',
        name: 'car',
        components: {
            default: () => import('../../views/car.vue'),
        }
    },
    {
        path: '/:base-biler/:brand/:model/:slug~:id(\\d+)',
        name: 'car2',
        components: {
            default: () => import('../../views/car.vue'),
        },
    },
    {
        path: '/:base-biler/:brand/:model',
        name: 'search-model',
        components: {
            default: () => import('../../views/list.vue'),
        }
    },
    {
        path: '/:base-biler/:brand',
        name: 'search-brand',
        components: {
            default: () => import('../../views/list.vue'),
        }
    },
    {
        path: '/:base-biler',
        name: 'search',
        components: {
            default: () => import('../../views/list.vue'),
        }
    }
];


const hashTagRoute = [
    {
        path: '/:brand/:model/:slug-:id(\\d+)',
        name: 'car',
        components: {
            default: () => import('../../views/car.vue'),
        }
    },
    {
        path: '/:brand/:model/:slug~:id(\\d+)',
        name: 'car2',
        components: {
            default: () => import('../../views/car.vue'),
        },
    },
    {
        path: '/',
        name: 'search',
        components: {
            default: () => import('../../views/list.vue'),
        }
    },
    {
        path: '/:brand/:model',
        name: 'search-model',
        components: {
            default: () => import('../../views/list.vue'),
        }
    },
    {
        path: '/:brand',
        name: 'search-brand',
        components: {
            default: () => import('../../views/list.vue'),
        }
    }
];

export const routerFn = (base, mode = 'hash') => {
    const r = createRouter({
        history: (mode === 'hash' ? createWebHashHistory(base) : createWebHistory()),
        routes: (mode === 'hash' ? hashTagRoute : routes),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return {left: 0, top: 0};
            }
        }
    });

    r.beforeEach(async(to, from, next) => {
        console.log('to', to);
        if(to.name === 'car' || to.name === 'car2'){
            doAll('.carads-intro-top','add', '!ca-hidden');
        }
        if(to.name === 'search' || to.name === 'search-brand' || to.name === 'search-model'){
            doAll('.carads-intro-top','remove', '!ca-hidden');
            // remove data-nosnippet from element
        }

        // if it comming from search-model and going to search-brand so make full reload
        if (from.name === 'search-model' && to.name === 'search-brand') {
            setTimeout(() => {
                window.scrollTo(0, 0);
                window.location.href = to.fullPath;
            }, 1000);
            return false;
        }
        if(from.name === 'search-brand' && to.name === 'search'){
            setTimeout(() => {
                window.scrollTo(0, 0);
                window.location.href = to.fullPath;
            }, 1000);
            return false;
        }
        return next();
    });

    return r;
}