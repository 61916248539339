import { createApp } from 'vue'
import './style.css'
import './icons.css'
import { routerFn } from "./core/router";
import { Currency, NumberFormat } from "./core/global/global";
import { createHead } from "@vueuse/head"

function searchApp(type){
    return import('./apps/Search.vue').then(({default: App}) => {
        const settings = JSON.parse(document.getElementById('carads_search_settings').textContent);
        const base = settings?.base?.toString() ?? null;

        if(type === 'slow')
        {
            settings.tag = 'a';
        }

        createApp(App)
            .use(routerFn(base, 'hash'))
            .use(createHead())
            .mixin({
                methods: {
                    $n: NumberFormat,
                    $c: Currency
                },
                data() {
                    return {
                        settings: {
                            ...settings,
                            filter:[
                                {
                                    "k": "details.brand",
                                    "s": 1,
                                    "l": "Mærke",
                                    "m": 0
                                },
                                {
                                    "k": "details.model",
                                    "s": 1,
                                    "l": "Model",
                                    "m": 0
                                },
                                {
                                    "k": "engine.propellant",
                                    "s": 1,
                                    "l": "Drivmiddel",
                                    "m": 0
                                },
                                {
                                    "k": "details.series",
                                    "s": 1,
                                    "l": "Serie",
                                    "m": 0
                                },
                                {
                                    "k": "info.color",
                                    "s": 1,
                                    "l": "Farve",
                                    "m": 0
                                },
                                {
                                    "k": "engine.kmprliter",
                                    "s": 1,
                                    "l": "Forbrug",
                                    "m": 0
                                },
                                {
                                    "k": "details.year",
                                    "s": 1,
                                    "l": "Modelår",
                                    "m": 0
                                },
                                {
                                    "k": "info.mileage",
                                    "s": 1,
                                    "l": "Kilometer",
                                    "m": 0,
                                    "f": "format"
                                },
                                {
                                    "k": "pricing.cash.price",
                                    "s": 1,
                                    "l": "Pris",
                                    "m": 0,
                                    "f": [
                                        "currency",
                                        false,
                                        {
                                            "currencyDisplay": "symbol",
                                            "decimals": 0
                                        }
                                    ]
                                },
                                {
                                    "k": "mobile",
                                    "s": 4,
                                    "l": "a",
                                    "m": 1
                                }
                            ]
                        }
                    }
                }
            })
            .mount(type === 'slow' ? '#app-slow' : '#app');
    });
}

// wait for document to be ready
(function(window){
    (function docReady(fn) {
        // see if DOM is already available
        if (document.readyState === "complete" || document.readyState === "interactive") {
            // call on next available tick
            setTimeout(fn, 1);
        } else {
            document.addEventListener("DOMContentLoaded", fn);
        }
    }(function (){
        if (document.getElementById('app')) {
            searchApp('fast').then(() => { });
        }
        if (document.getElementById('app-slow')) {
            searchApp('slow').then(() => { });
        }
    }))
})(window);